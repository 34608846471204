@media (max-width: 649px) {
  .fc-toolbar .fc-toolbar-title {
    font-size: 1.2em;
  }
}

.fc-list-event.fc-event-mobile td {
  padding: 2px 4px !important;
}

.fc-list-event.fc-event-mobile .fc-list-event-time {
  padding-left: 6px !important;
  padding-right: 6px !important;
  white-space: pre-line;
  text-align: center;
}

.fc-day-grid-event .fc-content {
  white-space: normal;
}

.fc-list-day th {
  z-index: 2;
}

.fc-list-day-cushion, /* mobile view, day name*/
.fc-list-event td {
  padding: 2px 14px !important;
}

.fc-daygrid-dot-event {
  padding: 0;
}

.fc-scroller {
    overflow-y: scroll !important;
}

.fc-daygrid-body.fc-daygrid-body-unbalanced, .fc-scrollgrid-sync-table, .fc-col-header {
    width: 100% !important;
}

.fc-toolbar-chunk {
  display: flex;
}