@import "~@fontsource/roboto/latin-300.css";
@import "~@fontsource/roboto/latin-400.css";
@import "~@fontsource/roboto/latin-500.css";
@import "~@fontsource/roboto/latin-700.css";

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.introjs-fixParent {
  position: absolute !important;
}

/* react-confirm-alert */
.react-confirm-alert-overlay {
  display: none !important;
}

:root {
  --safe-area-inset-bottom: 0;
  --safe-area-inset-bottom: constant(safe-area-inset-bottom);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
}


.cluster-marker {
  color: #fff;
  cursor: pointer;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  transform-origin: center center;
  transform: translate(-50%, -50%);
}

.unassigned-user-marker {
  cursor: pointer;
  transform-origin: bottom;
  transform: translate(-50%, -80%);
}
