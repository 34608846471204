.border-primary {
  border-color: #545cd8; }

.border-secondary {
  border-color: #6c757d; }

.border-success {
  border-color: #3ac47d; }

.border-info {
  border-color: #30b1ff; }

.border-warning {
  border-color: #f7b924; }

.border-danger {
  border-color: #d92550; }

.border-light {
  border-color: #eeeeee; }

.border-dark {
  border-color: #343a40; }

.border-focus {
  border-color: #444054; }

.border-alternate {
  border-color: #83588a; }

.list-group-item-primary {
  color: #2c3070;
  background-color: #cfd1f4; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #2c3070;
    background-color: #babdef; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #2c3070;
    border-color: #2c3070; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #1e6641;
  background-color: #c8eedb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #1e6641;
    background-color: #b5e8ce; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #1e6641;
    border-color: #1e6641; }

.list-group-item-info {
  color: #195c85;
  background-color: #c5e9ff; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #195c85;
    background-color: #acdfff; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #195c85;
    border-color: #195c85; }

.list-group-item-warning {
  color: #806013;
  background-color: #fdebc2; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #806013;
    background-color: #fce3a9; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #806013;
    border-color: #806013; }

.list-group-item-danger {
  color: #71132a;
  background-color: #f4c2ce; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #71132a;
    background-color: #f0acbd; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #71132a;
    border-color: #71132a; }

.list-group-item-light {
  color: #7c7c7c;
  background-color: #fafafa; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #7c7c7c;
    background-color: #ededed; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #7c7c7c;
    border-color: #7c7c7c; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.list-group-item-focus {
  color: #23212c;
  background-color: #cbcacf; }
  .list-group-item-focus.list-group-item-action:hover, .list-group-item-focus.list-group-item-action:focus {
    color: #23212c;
    background-color: #bebdc3; }
  .list-group-item-focus.list-group-item-action.active {
    color: #fff;
    background-color: #23212c;
    border-color: #23212c; }

.list-group-item-alternate {
  color: #442e48;
  background-color: #dcd0de; }
  .list-group-item-alternate.list-group-item-action:hover, .list-group-item-alternate.list-group-item-action:focus {
    color: #442e48;
    background-color: #d1c1d3; }
  .list-group-item-alternate.list-group-item-action.active {
    color: #fff;
    background-color: #442e48;
    border-color: #442e48; }

.bg-primary {
  background-color: #545cd8 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #2e38cb !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #3ac47d !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #2e9d64 !important; }

.bg-info {
  background-color: #30b1ff !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #009dfc !important; }

.bg-warning {
  background-color: #f7b924 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #e0a008 !important; }

.bg-danger {
  background-color: #d92550 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ad1e40 !important; }

.bg-light {
  background-color: #eeeeee !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #d5d5d5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-focus {
  background-color: #444054 !important; }

a.bg-focus:hover, a.bg-focus:focus,
button.bg-focus:hover,
button.bg-focus:focus {
  background-color: #2d2a37 !important; }

.bg-alternate {
  background-color: #83588a !important; }

a.bg-alternate:hover, a.bg-alternate:focus,
button.bg-alternate:hover,
button.bg-alternate:focus {
  background-color: #65446b !important; }

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #545cd8;
  --secondary: #6c757d;
  --success: #3ac47d;
  --info: #30b1ff;
  --warning: #f7b924;
  --danger: #d92550;
  --light: #eeeeee;
  --dark: #343a40;
  --focus: #444054;
  --alternate: #83588a;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #495057;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s, background-color 0.15s, border-color 0.15s, box-shadow 0.15s; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #495057;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: none; }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #545cd8;
  border-color: #545cd8; }
  .btn-primary:hover {
    color: #fff;
    background-color: #353ed1;
    border-color: #2e38cb; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0 rgba(110, 116, 222, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #545cd8;
    border-color: #545cd8; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #2e38cb;
    border-color: #2c35c0; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(110, 116, 222, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0 rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #3ac47d;
  border-color: #3ac47d; }
  .btn-success:hover {
    color: #fff;
    background-color: #31a66a;
    border-color: #2e9d64; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0 rgba(88, 205, 145, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #3ac47d;
    border-color: #3ac47d; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #2e9d64;
    border-color: #2b935e; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(88, 205, 145, 0.5); }

.btn-info {
  color: #fff;
  background-color: #30b1ff;
  border-color: #30b1ff; }
  .btn-info:hover {
    color: #fff;
    background-color: #0aa3ff;
    border-color: #009dfc; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0 rgba(79, 189, 255, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #30b1ff;
    border-color: #30b1ff; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #009dfc;
    border-color: #0095ef; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(79, 189, 255, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #f7b924;
  border-color: #f7b924; }
  .btn-warning:hover {
    color: #212529;
    background-color: #eca909;
    border-color: #e0a008; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0 rgba(215, 163, 37, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #f7b924;
    border-color: #f7b924; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #e0a008;
    border-color: #d49808; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(215, 163, 37, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #d92550;
  border-color: #d92550; }
  .btn-danger:hover {
    color: #fff;
    background-color: #b81f44;
    border-color: #ad1e40; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0 rgba(223, 70, 106, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #d92550;
    border-color: #d92550; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ad1e40;
    border-color: #a31c3c; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(223, 70, 106, 0.5); }

.btn-light {
  color: #212529;
  background-color: #eeeeee;
  border-color: #eeeeee; }
  .btn-light:hover {
    color: #212529;
    background-color: #dbdbdb;
    border-color: #d5d5d5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0 rgba(207, 208, 208, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #eeeeee;
    border-color: #eeeeee; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #d5d5d5;
    border-color: #cecece; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(207, 208, 208, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0 rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(82, 88, 93, 0.5); }

.btn-focus {
  color: #fff;
  background-color: #444054;
  border-color: #444054; }
  .btn-focus:hover {
    color: #fff;
    background-color: #322f3e;
    border-color: #2d2a37; }
  .btn-focus:focus, .btn-focus.focus {
    box-shadow: 0 0 0 0 rgba(96, 93, 110, 0.5); }
  .btn-focus.disabled, .btn-focus:disabled {
    color: #fff;
    background-color: #444054;
    border-color: #444054; }
  .btn-focus:not(:disabled):not(.disabled):active, .btn-focus:not(:disabled):not(.disabled).active,
  .show > .btn-focus.dropdown-toggle {
    color: #fff;
    background-color: #2d2a37;
    border-color: #272430; }
    .btn-focus:not(:disabled):not(.disabled):active:focus, .btn-focus:not(:disabled):not(.disabled).active:focus,
    .show > .btn-focus.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(96, 93, 110, 0.5); }

.btn-alternate {
  color: #fff;
  background-color: #83588a;
  border-color: #83588a; }
  .btn-alternate:hover {
    color: #fff;
    background-color: #6d4973;
    border-color: #65446b; }
  .btn-alternate:focus, .btn-alternate.focus {
    box-shadow: 0 0 0 0 rgba(150, 113, 156, 0.5); }
  .btn-alternate.disabled, .btn-alternate:disabled {
    color: #fff;
    background-color: #83588a;
    border-color: #83588a; }
  .btn-alternate:not(:disabled):not(.disabled):active, .btn-alternate:not(:disabled):not(.disabled).active,
  .show > .btn-alternate.dropdown-toggle {
    color: #fff;
    background-color: #65446b;
    border-color: #5e3f63; }
    .btn-alternate:not(:disabled):not(.disabled):active:focus, .btn-alternate:not(:disabled):not(.disabled).active:focus,
    .show > .btn-alternate.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(150, 113, 156, 0.5); }

.btn-outline-primary {
  color: #545cd8;
  border-color: #545cd8; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #545cd8;
    border-color: #545cd8; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0 rgba(84, 92, 216, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #545cd8;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #545cd8;
    border-color: #545cd8; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(84, 92, 216, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0 rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #3ac47d;
  border-color: #3ac47d; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #3ac47d;
    border-color: #3ac47d; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0 rgba(58, 196, 125, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #3ac47d;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #3ac47d;
    border-color: #3ac47d; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(58, 196, 125, 0.5); }

.btn-outline-info {
  color: #30b1ff;
  border-color: #30b1ff; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #30b1ff;
    border-color: #30b1ff; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0 rgba(48, 177, 255, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #30b1ff;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #30b1ff;
    border-color: #30b1ff; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(48, 177, 255, 0.5); }

.btn-outline-warning {
  color: #f7b924;
  border-color: #f7b924; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #f7b924;
    border-color: #f7b924; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0 rgba(247, 185, 36, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #f7b924;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #f7b924;
    border-color: #f7b924; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(247, 185, 36, 0.5); }

.btn-outline-danger {
  color: #d92550;
  border-color: #d92550; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #d92550;
    border-color: #d92550; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0 rgba(217, 37, 80, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #d92550;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #d92550;
    border-color: #d92550; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(217, 37, 80, 0.5); }

.btn-outline-light {
  color: #eeeeee;
  border-color: #eeeeee; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #eeeeee;
    border-color: #eeeeee; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0 rgba(238, 238, 238, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #eeeeee;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #eeeeee;
    border-color: #eeeeee; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(238, 238, 238, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0 rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(52, 58, 64, 0.5); }

.btn-outline-focus {
  color: #444054;
  border-color: #444054; }
  .btn-outline-focus:hover {
    color: #fff;
    background-color: #444054;
    border-color: #444054; }
  .btn-outline-focus:focus, .btn-outline-focus.focus {
    box-shadow: 0 0 0 0 rgba(68, 64, 84, 0.5); }
  .btn-outline-focus.disabled, .btn-outline-focus:disabled {
    color: #444054;
    background-color: transparent; }
  .btn-outline-focus:not(:disabled):not(.disabled):active, .btn-outline-focus:not(:disabled):not(.disabled).active,
  .show > .btn-outline-focus.dropdown-toggle {
    color: #fff;
    background-color: #444054;
    border-color: #444054; }
    .btn-outline-focus:not(:disabled):not(.disabled):active:focus, .btn-outline-focus:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-focus.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(68, 64, 84, 0.5); }

.btn-outline-alternate {
  color: #83588a;
  border-color: #83588a; }
  .btn-outline-alternate:hover {
    color: #fff;
    background-color: #83588a;
    border-color: #83588a; }
  .btn-outline-alternate:focus, .btn-outline-alternate.focus {
    box-shadow: 0 0 0 0 rgba(131, 88, 138, 0.5); }
  .btn-outline-alternate.disabled, .btn-outline-alternate:disabled {
    color: #83588a;
    background-color: transparent; }
  .btn-outline-alternate:not(:disabled):not(.disabled):active, .btn-outline-alternate:not(:disabled):not(.disabled).active,
  .show > .btn-outline-alternate.dropdown-toggle {
    color: #fff;
    background-color: #83588a;
    border-color: #83588a; }
    .btn-outline-alternate:not(:disabled):not(.disabled):active:focus, .btn-outline-alternate:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-alternate.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(131, 88, 138, 0.5); }

.btn-link {
  font-weight: 400;
  color: #545cd8;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(32, 39, 140, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(32, 39, 140, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border-top: 1px solid rgba(32, 39, 140, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #545cd8; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #2e38cb; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(84, 92, 216, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #3ac47d; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #2e9d64; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(58, 196, 125, 0.5); }

.badge-info {
  color: #fff;
  background-color: #30b1ff; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #009dfc; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(48, 177, 255, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #f7b924; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #e0a008; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(247, 185, 36, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #d92550; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #ad1e40; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(217, 37, 80, 0.5); }

.badge-light {
  color: #212529;
  background-color: #eeeeee; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #d5d5d5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(238, 238, 238, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.badge-focus {
  color: #fff;
  background-color: #444054; }
  a.badge-focus:hover, a.badge-focus:focus {
    color: #fff;
    background-color: #2d2a37; }
  a.badge-focus:focus, a.badge-focus.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(68, 64, 84, 0.5); }

.badge-alternate {
  color: #fff;
  background-color: #83588a; }
  a.badge-alternate:hover, a.badge-alternate:focus {
    color: #fff;
    background-color: #65446b; }
  a.badge-alternate:focus, a.badge-alternate.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(131, 88, 138, 0.5); }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #545cd8 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #2e38cb !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #3ac47d !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #2e9d64 !important; }

.bg-info {
  background-color: #30b1ff !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #009dfc !important; }

.bg-warning {
  background-color: #f7b924 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #e0a008 !important; }

.bg-danger {
  background-color: #d92550 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ad1e40 !important; }

.bg-light {
  background-color: #eeeeee !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #d5d5d5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-focus {
  background-color: #444054 !important; }

a.bg-focus:hover, a.bg-focus:focus,
button.bg-focus:hover,
button.bg-focus:focus {
  background-color: #2d2a37 !important; }

.bg-alternate {
  background-color: #83588a !important; }

a.bg-alternate:hover, a.bg-alternate:focus,
button.bg-alternate:hover,
button.bg-alternate:focus {
  background-color: #65446b !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #545cd8 !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #3ac47d !important; }

.border-info {
  border-color: #30b1ff !important; }

.border-warning {
  border-color: #f7b924 !important; }

.border-danger {
  border-color: #d92550 !important; }

.border-light {
  border-color: #eeeeee !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-focus {
  border-color: #444054 !important; }

.border-alternate {
  border-color: #83588a !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #545cd8 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #2a32b6 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #3ac47d !important; }

a.text-success:hover, a.text-success:focus {
  color: #298957 !important; }

.text-info {
  color: #30b1ff !important; }

a.text-info:hover, a.text-info:focus {
  color: #008de3 !important; }

.text-warning {
  color: #f7b924 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #c78f07 !important; }

.text-danger {
  color: #d92550 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #981a38 !important; }

.text-light {
  color: #eeeeee !important; }

a.text-light:hover, a.text-light:focus {
  color: #c8c8c8 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-focus {
  color: #444054 !important; }

a.text-focus:hover, a.text-focus:focus {
  color: #211f29 !important; }

.text-alternate {
  color: #83588a !important; }

a.text-alternate:hover, a.text-alternate:focus {
  color: #573a5b !important; }

.text-body {
  color: #495057 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.btn-outline-2x {
  border-width: 2px; }

.btn-group .btn {
  font-size: 0.8rem;
  font-weight: 500; }

.btn-group .btn-outline-2x + .btn-outline-2x {
  margin-left: -2px; }

.btn-group .btn-square {
  border-radius: 0; }

.btn {
  font-size: 0.8rem;
  font-weight: 500; }
  .btn.btn-pill.btn-wide, .btn.btn-pill {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px; }

.btn-dashed {
  border-style: dashed; }

.btn-icon {
  vertical-align: bottom; }
  .btn-icon.btn-icon-right .btn-icon-wrapper {
    margin-left: 0.5rem;
    margin-right: 0; }
  .btn-icon .btn-icon-wrapper {
    margin-right: 0.5rem;
    margin-left: 0;
    margin-top: 0;
    font-size: 17px;
    vertical-align: middle;
    transition: color .1s;
    display: inline-block; }
  .btn-icon.btn-link {
    text-decoration: none; }
  .btn-icon.btn-lg:not(.btn-block) .btn-icon-wrapper, .btn-group-lg > .btn-icon.btn:not(.btn-block) .btn-icon-wrapper {
    font-size: 25px; }
  .btn-icon.btn-sm:not(.btn-block) .btn-icon-wrapper, .btn-group-sm > .btn-icon.btn:not(.btn-block) .btn-icon-wrapper {
    font-size: 16px; }

.btn-icon-only .btn-icon-wrapper {
  margin-left: 0;
  margin-right: 0; }

.btn-hover-shine {
  position: relative; }
  .btn-hover-shine:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    -webkit-transition: none;
    -moz-transition: none;
    transition: none; }
  .btn-hover-shine:hover:after {
    width: 120%;
    background-color: rgba(255, 255, 255, 0);
    transition: all 0.4s ease-in-out; }
  .btn-hover-shine.btn-pill::after {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px; }

.btn-icon-vertical {
  padding-top: 1rem;
  padding-bottom: 1rem; }
  .btn-icon-vertical .btn-icon-wrapper {
    display: block;
    font-size: 200%;
    margin: 5px 0;
    opacity: .6; }
  .btn-icon-vertical.btn-link {
    text-decoration: none; }
  .btn-icon-vertical.btn-lg:not(.btn-block) .btn-icon-wrapper, .btn-group-lg > .btn-icon-vertical.btn:not(.btn-block) .btn-icon-wrapper {
    font-size: 25px; }
  .btn-icon-vertical.btn-sm:not(.btn-block) .btn-icon-wrapper, .btn-group-sm > .btn-icon-vertical.btn:not(.btn-block) .btn-icon-wrapper {
    font-size: 16px; }
  .btn-icon-vertical:active .btn-icon-wrapper, .btn-icon-vertical.active .btn-icon-wrapper, .btn-icon-vertical:hover .btn-icon-wrapper {
    opacity: 1; }
  .btn-icon-vertical.btn-icon-bottom .btn-icon-wrapper {
    margin: 0.2rem 0 5px; }
  .btn-icon-vertical.btn-transition-text .btn-icon-wrapper {
    transition: all .2s !important; }
  .btn-icon-vertical.btn-transition-text:hover .btn-icon-wrapper {
    transform: scale(1.3); }
  .btn-icon-vertical.btn-transition-text.btn-transition-alt:hover .btn-icon-wrapper {
    color: #fff !important; }

.btn-icon-lg {
  font-size: 2.5rem !important; }

.btn-transition {
  color: #6c757d;
  border-color: #e9ecef;
  background-color: none; }
  .btn-transition.btn-outline-link {
    border-color: transparent;
    background-color: transparent; }
    .btn-transition.btn-outline-link:hover {
      color: #545cd8;
      background: #f8f9fa; }
  .btn-transition.disabled, .btn-transition:disabled {
    color: #6c757d;
    border-color: #e9ecef; }
  .btn-transition:hover .btn-icon-wrapper {
    transition: none; }

.btn-transition-alt:hover .icon-gradient {
  -webkit-background-clip: initial;
  -webkit-text-fill-color: initial;
  background-clip: initial;
  text-fill-color: initial;
  background: none !important;
  color: #fff; }

.btn-square {
  border-radius: 0 !important; }

.btn.btn-wide {
  padding: 0.375rem 1.5rem;
  font-size: 0.8rem;
  line-height: 1.5;
  border-radius: 0.25rem; }

.btn-lg.btn-wide, .btn-group-lg > .btn-wide.btn {
  padding: 0.5rem 2rem;
  font-size: 1.1rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm.btn-wide, .btn-group-sm > .btn-wide.btn {
  padding: 0.25rem 1rem;
  font-size: 0.8rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.dropdown-toggle::after {
  position: relative;
  top: 2px;
  opacity: .8; }

.dropright .dropdown-toggle::after {
  top: 0; }

.dropdown-toggle-split {
  border-left: rgba(255, 255, 255, 0.1) solid 1px; }

.btn-gradient-primary {
  background-image: linear-gradient(140deg, #2a32b6 -30%, #545cd8 90%);
  background-color: #2a32b6;
  border-color: #2a32b6;
  color: #fff; }
  .btn-gradient-primary.active, .btn-gradient-primary:active, .btn-gradient-primary:not(:disabled):not(.disabled):hover {
    background-image: linear-gradient(120deg, #272faa 0%, #4850d5 100%);
    color: #fff;
    border-color: #20278c; }
  .btn-gradient-primary:focus, .btn-gradient-primary.focus, .btn-gradient-primary:active, .btn-gradient-primary.active {
    color: #fff !important;
    border-color: #252ca1 !important; }
  .btn-gradient-primary.btn-shadow {
    box-shadow: 0 0.125rem 0.625rem rgba(84, 92, 216, 0.4), 0 0.0625rem 0.125rem rgba(84, 92, 216, 0.5); }
    .btn-gradient-primary.btn-shadow:hover {
      box-shadow: 0 0.125rem 0.625rem rgba(84, 92, 216, 0.5), 0 0.0625rem 0.125rem rgba(84, 92, 216, 0.6); }

.btn-gradient-secondary {
  background-image: linear-gradient(140deg, #494f54 -30%, #6c757d 90%);
  background-color: #494f54;
  border-color: #494f54;
  color: #fff; }
  .btn-gradient-secondary.active, .btn-gradient-secondary:active, .btn-gradient-secondary:not(:disabled):not(.disabled):hover {
    background-image: linear-gradient(120deg, #41474c 0%, #656d75 100%);
    color: #fff;
    border-color: #313539; }
  .btn-gradient-secondary:focus, .btn-gradient-secondary.focus, .btn-gradient-secondary:active, .btn-gradient-secondary.active {
    color: #fff !important;
    border-color: #3d4246 !important; }
  .btn-gradient-secondary.btn-shadow {
    box-shadow: 0 0.125rem 0.625rem rgba(108, 117, 125, 0.4), 0 0.0625rem 0.125rem rgba(108, 117, 125, 0.5); }
    .btn-gradient-secondary.btn-shadow:hover {
      box-shadow: 0 0.125rem 0.625rem rgba(108, 117, 125, 0.5), 0 0.0625rem 0.125rem rgba(108, 117, 125, 0.6); }

.btn-gradient-success {
  background-image: linear-gradient(140deg, #298957 -30%, #3ac47d 90%);
  background-color: #298957;
  border-color: #298957;
  color: #fff; }
  .btn-gradient-success.active, .btn-gradient-success:active, .btn-gradient-success:not(:disabled):not(.disabled):hover {
    background-image: linear-gradient(120deg, #257d50 0%, #37b875 100%);
    color: #fff;
    border-color: #1d623e; }
  .btn-gradient-success:focus, .btn-gradient-success.focus, .btn-gradient-success:active, .btn-gradient-success.active {
    color: #fff !important;
    border-color: #23754b !important; }
  .btn-gradient-success.btn-shadow {
    box-shadow: 0 0.125rem 0.625rem rgba(58, 196, 125, 0.4), 0 0.0625rem 0.125rem rgba(58, 196, 125, 0.5); }
    .btn-gradient-success.btn-shadow:hover {
      box-shadow: 0 0.125rem 0.625rem rgba(58, 196, 125, 0.5), 0 0.0625rem 0.125rem rgba(58, 196, 125, 0.6); }

.btn-gradient-info {
  background-image: linear-gradient(140deg, #008de3 -30%, #30b1ff 90%);
  background-color: #008de3;
  border-color: #008de3;
  color: #fff; }
  .btn-gradient-info.active, .btn-gradient-info:active, .btn-gradient-info:not(:disabled):not(.disabled):hover {
    background-image: linear-gradient(120deg, #0084d3 0%, #21abff 100%);
    color: #fff;
    border-color: #006db0; }
  .btn-gradient-info:focus, .btn-gradient-info.focus, .btn-gradient-info:active, .btn-gradient-info.active {
    color: #fff !important;
    border-color: #007dc9 !important; }
  .btn-gradient-info.btn-shadow {
    box-shadow: 0 0.125rem 0.625rem rgba(48, 177, 255, 0.4), 0 0.0625rem 0.125rem rgba(48, 177, 255, 0.5); }
    .btn-gradient-info.btn-shadow:hover {
      box-shadow: 0 0.125rem 0.625rem rgba(48, 177, 255, 0.5), 0 0.0625rem 0.125rem rgba(48, 177, 255, 0.6); }

.btn-gradient-warning {
  background-image: linear-gradient(140deg, #c78f07 -30%, #f7b924 90%);
  background-color: #c78f07;
  border-color: #c78f07;
  color: #fff; }
  .btn-gradient-warning.active, .btn-gradient-warning:active, .btn-gradient-warning:not(:disabled):not(.disabled):hover {
    background-image: linear-gradient(120deg, #b88407 0%, #f6b415 100%);
    color: #fff;
    border-color: #966c05; }
  .btn-gradient-warning:focus, .btn-gradient-warning.focus, .btn-gradient-warning:active, .btn-gradient-warning.active {
    color: #fff !important;
    border-color: #af7d06 !important; }
  .btn-gradient-warning.btn-shadow {
    box-shadow: 0 0.125rem 0.625rem rgba(247, 185, 36, 0.4), 0 0.0625rem 0.125rem rgba(247, 185, 36, 0.5); }
    .btn-gradient-warning.btn-shadow:hover {
      box-shadow: 0 0.125rem 0.625rem rgba(247, 185, 36, 0.5), 0 0.0625rem 0.125rem rgba(247, 185, 36, 0.6); }

.btn-gradient-danger {
  background-image: linear-gradient(140deg, #981a38 -30%, #d92550 90%);
  background-color: #981a38;
  border-color: #981a38;
  color: #fff; }
  .btn-gradient-danger.active, .btn-gradient-danger:active, .btn-gradient-danger:not(:disabled):not(.disabled):hover {
    background-image: linear-gradient(120deg, #8b1833 0%, #cc234b 100%);
    color: #fff;
    border-color: #6c1228; }
  .btn-gradient-danger:focus, .btn-gradient-danger.focus, .btn-gradient-danger:active, .btn-gradient-danger.active {
    color: #fff !important;
    border-color: #821630 !important; }
  .btn-gradient-danger.btn-shadow {
    box-shadow: 0 0.125rem 0.625rem rgba(217, 37, 80, 0.4), 0 0.0625rem 0.125rem rgba(217, 37, 80, 0.5); }
    .btn-gradient-danger.btn-shadow:hover {
      box-shadow: 0 0.125rem 0.625rem rgba(217, 37, 80, 0.5), 0 0.0625rem 0.125rem rgba(217, 37, 80, 0.6); }

.btn-gradient-focus {
  background-image: linear-gradient(140deg, #211f29 -30%, #444054 90%);
  background-color: #211f29;
  border-color: #211f29;
  color: #fff; }
  .btn-gradient-focus.active, .btn-gradient-focus:active, .btn-gradient-focus:not(:disabled):not(.disabled):hover {
    background-image: linear-gradient(120deg, #1a1820 0%, #3d394b 100%);
    color: #fff;
    border-color: #09090c; }
  .btn-gradient-focus:focus, .btn-gradient-focus.focus, .btn-gradient-focus:active, .btn-gradient-focus.active {
    color: #fff !important;
    border-color: #15141a !important; }
  .btn-gradient-focus.btn-shadow {
    box-shadow: 0 0.125rem 0.625rem rgba(68, 64, 84, 0.4), 0 0.0625rem 0.125rem rgba(68, 64, 84, 0.5); }
    .btn-gradient-focus.btn-shadow:hover {
      box-shadow: 0 0.125rem 0.625rem rgba(68, 64, 84, 0.5), 0 0.0625rem 0.125rem rgba(68, 64, 84, 0.6); }

.btn-gradient-alternate {
  background-image: linear-gradient(140deg, #573a5b -30%, #83588a 90%);
  background-color: #573a5b;
  border-color: #573a5b;
  color: #fff; }
  .btn-gradient-alternate.active, .btn-gradient-alternate:active, .btn-gradient-alternate:not(:disabled):not(.disabled):hover {
    background-image: linear-gradient(120deg, #4e3452 0%, #7a5281 100%);
    color: #fff;
    border-color: #39263c; }
  .btn-gradient-alternate:focus, .btn-gradient-alternate.focus, .btn-gradient-alternate:active, .btn-gradient-alternate.active {
    color: #fff !important;
    border-color: #48304c !important; }
  .btn-gradient-alternate.btn-shadow {
    box-shadow: 0 0.125rem 0.625rem rgba(131, 88, 138, 0.4), 0 0.0625rem 0.125rem rgba(131, 88, 138, 0.5); }
    .btn-gradient-alternate.btn-shadow:hover {
      box-shadow: 0 0.125rem 0.625rem rgba(131, 88, 138, 0.5), 0 0.0625rem 0.125rem rgba(131, 88, 138, 0.6); }

.btn-gradient-light {
  background-image: linear-gradient(140deg, #c8c8c8 -30%, #eeeeee 90%);
  background-color: #c8c8c8;
  border-color: #c8c8c8;
  color: #212529; }
  .btn-gradient-light.active, .btn-gradient-light:active, .btn-gradient-light:not(:disabled):not(.disabled):hover {
    background-image: linear-gradient(120deg, silver 0%, #e6e6e6 100%);
    color: #212529;
    border-color: #aeaeae; }
  .btn-gradient-light:focus, .btn-gradient-light.focus, .btn-gradient-light:active, .btn-gradient-light.active {
    color: #212529 !important;
    border-color: #bbbbbb !important; }
  .btn-gradient-light.btn-shadow {
    box-shadow: 0 0.125rem 0.625rem rgba(238, 238, 238, 0.4), 0 0.0625rem 0.125rem rgba(238, 238, 238, 0.5); }
    .btn-gradient-light.btn-shadow:hover {
      box-shadow: 0 0.125rem 0.625rem rgba(238, 238, 238, 0.5), 0 0.0625rem 0.125rem rgba(238, 238, 238, 0.6); }

.btn-gradient-dark {
  background-image: linear-gradient(140deg, #121416 -30%, #343a40 90%);
  background-color: #121416;
  border-color: #121416;
  color: #fff; }
  .btn-gradient-dark.active, .btn-gradient-dark:active, .btn-gradient-dark:not(:disabled):not(.disabled):hover {
    background-image: linear-gradient(120deg, #0b0c0d 0%, #2d3238 100%);
    color: #fff;
    border-color: black; }
  .btn-gradient-dark:focus, .btn-gradient-dark.focus, .btn-gradient-dark:active, .btn-gradient-dark.active {
    color: #fff !important;
    border-color: #060708 !important; }
  .btn-gradient-dark.btn-shadow {
    box-shadow: 0 0.125rem 0.625rem rgba(52, 58, 64, 0.4), 0 0.0625rem 0.125rem rgba(52, 58, 64, 0.5); }
    .btn-gradient-dark.btn-shadow:hover {
      box-shadow: 0 0.125rem 0.625rem rgba(52, 58, 64, 0.5), 0 0.0625rem 0.125rem rgba(52, 58, 64, 0.6); }

.btn-shadow.active {
  box-shadow: 0 0 0 0 transparent !important; }

.btn-primary {
  color: #fff;
  background-color: #545cd8;
  border-color: #545cd8; }
  .btn-primary:hover {
    color: #fff;
    background-color: #353ed1;
    border-color: #2e38cb; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0 rgba(110, 116, 222, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #545cd8;
    border-color: #545cd8; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #2e38cb;
    border-color: #2c35c0; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(110, 116, 222, 0.5); }
  .btn-primary.btn-shadow {
    box-shadow: 0 0.125rem 0.625rem rgba(84, 92, 216, 0.4), 0 0.0625rem 0.125rem rgba(84, 92, 216, 0.5); }
    .btn-primary.btn-shadow:hover {
      box-shadow: 0 0.125rem 0.625rem rgba(84, 92, 216, 0.5), 0 0.0625rem 0.125rem rgba(84, 92, 216, 0.6); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0 rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(130, 138, 145, 0.5); }
  .btn-secondary.btn-shadow {
    box-shadow: 0 0.125rem 0.625rem rgba(108, 117, 125, 0.4), 0 0.0625rem 0.125rem rgba(108, 117, 125, 0.5); }
    .btn-secondary.btn-shadow:hover {
      box-shadow: 0 0.125rem 0.625rem rgba(108, 117, 125, 0.5), 0 0.0625rem 0.125rem rgba(108, 117, 125, 0.6); }

.btn-success {
  color: #fff;
  background-color: #3ac47d;
  border-color: #3ac47d; }
  .btn-success:hover {
    color: #fff;
    background-color: #31a66a;
    border-color: #2e9d64; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0 rgba(88, 205, 145, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #3ac47d;
    border-color: #3ac47d; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #2e9d64;
    border-color: #2b935e; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(88, 205, 145, 0.5); }
  .btn-success.btn-shadow {
    box-shadow: 0 0.125rem 0.625rem rgba(58, 196, 125, 0.4), 0 0.0625rem 0.125rem rgba(58, 196, 125, 0.5); }
    .btn-success.btn-shadow:hover {
      box-shadow: 0 0.125rem 0.625rem rgba(58, 196, 125, 0.5), 0 0.0625rem 0.125rem rgba(58, 196, 125, 0.6); }

.btn-info {
  color: #fff;
  background-color: #30b1ff;
  border-color: #30b1ff; }
  .btn-info:hover {
    color: #fff;
    background-color: #0aa3ff;
    border-color: #009dfc; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0 rgba(79, 189, 255, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #30b1ff;
    border-color: #30b1ff; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #009dfc;
    border-color: #0095ef; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(79, 189, 255, 0.5); }
  .btn-info.btn-shadow {
    box-shadow: 0 0.125rem 0.625rem rgba(48, 177, 255, 0.4), 0 0.0625rem 0.125rem rgba(48, 177, 255, 0.5); }
    .btn-info.btn-shadow:hover {
      box-shadow: 0 0.125rem 0.625rem rgba(48, 177, 255, 0.5), 0 0.0625rem 0.125rem rgba(48, 177, 255, 0.6); }

.btn-warning {
  color: #212529;
  background-color: #f7b924;
  border-color: #f7b924; }
  .btn-warning:hover {
    color: #212529;
    background-color: #eca909;
    border-color: #e0a008; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0 rgba(215, 163, 37, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #f7b924;
    border-color: #f7b924; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #e0a008;
    border-color: #d49808; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(215, 163, 37, 0.5); }
  .btn-warning.btn-shadow {
    box-shadow: 0 0.125rem 0.625rem rgba(247, 185, 36, 0.4), 0 0.0625rem 0.125rem rgba(247, 185, 36, 0.5); }
    .btn-warning.btn-shadow:hover {
      box-shadow: 0 0.125rem 0.625rem rgba(247, 185, 36, 0.5), 0 0.0625rem 0.125rem rgba(247, 185, 36, 0.6); }

.btn-danger {
  color: #fff;
  background-color: #d92550;
  border-color: #d92550; }
  .btn-danger:hover {
    color: #fff;
    background-color: #b81f44;
    border-color: #ad1e40; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0 rgba(223, 70, 106, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #d92550;
    border-color: #d92550; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ad1e40;
    border-color: #a31c3c; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(223, 70, 106, 0.5); }
  .btn-danger.btn-shadow {
    box-shadow: 0 0.125rem 0.625rem rgba(217, 37, 80, 0.4), 0 0.0625rem 0.125rem rgba(217, 37, 80, 0.5); }
    .btn-danger.btn-shadow:hover {
      box-shadow: 0 0.125rem 0.625rem rgba(217, 37, 80, 0.5), 0 0.0625rem 0.125rem rgba(217, 37, 80, 0.6); }

.btn-light {
  color: #212529;
  background-color: #eeeeee;
  border-color: #eeeeee; }
  .btn-light:hover {
    color: #212529;
    background-color: #dbdbdb;
    border-color: #d5d5d5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0 rgba(207, 208, 208, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #eeeeee;
    border-color: #eeeeee; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #d5d5d5;
    border-color: #cecece; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(207, 208, 208, 0.5); }
  .btn-light.btn-shadow {
    box-shadow: 0 0.125rem 0.625rem rgba(238, 238, 238, 0.4), 0 0.0625rem 0.125rem rgba(238, 238, 238, 0.5); }
    .btn-light.btn-shadow:hover {
      box-shadow: 0 0.125rem 0.625rem rgba(238, 238, 238, 0.5), 0 0.0625rem 0.125rem rgba(238, 238, 238, 0.6); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0 rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(82, 88, 93, 0.5); }
  .btn-dark.btn-shadow {
    box-shadow: 0 0.125rem 0.625rem rgba(52, 58, 64, 0.4), 0 0.0625rem 0.125rem rgba(52, 58, 64, 0.5); }
    .btn-dark.btn-shadow:hover {
      box-shadow: 0 0.125rem 0.625rem rgba(52, 58, 64, 0.5), 0 0.0625rem 0.125rem rgba(52, 58, 64, 0.6); }

.btn-focus {
  color: #fff;
  background-color: #444054;
  border-color: #444054; }
  .btn-focus:hover {
    color: #fff;
    background-color: #322f3e;
    border-color: #2d2a37; }
  .btn-focus:focus, .btn-focus.focus {
    box-shadow: 0 0 0 0 rgba(96, 93, 110, 0.5); }
  .btn-focus.disabled, .btn-focus:disabled {
    color: #fff;
    background-color: #444054;
    border-color: #444054; }
  .btn-focus:not(:disabled):not(.disabled):active, .btn-focus:not(:disabled):not(.disabled).active,
  .show > .btn-focus.dropdown-toggle {
    color: #fff;
    background-color: #2d2a37;
    border-color: #272430; }
    .btn-focus:not(:disabled):not(.disabled):active:focus, .btn-focus:not(:disabled):not(.disabled).active:focus,
    .show > .btn-focus.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(96, 93, 110, 0.5); }
  .btn-focus.btn-shadow {
    box-shadow: 0 0.125rem 0.625rem rgba(68, 64, 84, 0.4), 0 0.0625rem 0.125rem rgba(68, 64, 84, 0.5); }
    .btn-focus.btn-shadow:hover {
      box-shadow: 0 0.125rem 0.625rem rgba(68, 64, 84, 0.5), 0 0.0625rem 0.125rem rgba(68, 64, 84, 0.6); }

.btn-alternate {
  color: #fff;
  background-color: #83588a;
  border-color: #83588a; }
  .btn-alternate:hover {
    color: #fff;
    background-color: #6d4973;
    border-color: #65446b; }
  .btn-alternate:focus, .btn-alternate.focus {
    box-shadow: 0 0 0 0 rgba(150, 113, 156, 0.5); }
  .btn-alternate.disabled, .btn-alternate:disabled {
    color: #fff;
    background-color: #83588a;
    border-color: #83588a; }
  .btn-alternate:not(:disabled):not(.disabled):active, .btn-alternate:not(:disabled):not(.disabled).active,
  .show > .btn-alternate.dropdown-toggle {
    color: #fff;
    background-color: #65446b;
    border-color: #5e3f63; }
    .btn-alternate:not(:disabled):not(.disabled):active:focus, .btn-alternate:not(:disabled):not(.disabled).active:focus,
    .show > .btn-alternate.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(150, 113, 156, 0.5); }
  .btn-alternate.btn-shadow {
    box-shadow: 0 0.125rem 0.625rem rgba(131, 88, 138, 0.4), 0 0.0625rem 0.125rem rgba(131, 88, 138, 0.5); }
    .btn-alternate.btn-shadow:hover {
      box-shadow: 0 0.125rem 0.625rem rgba(131, 88, 138, 0.5), 0 0.0625rem 0.125rem rgba(131, 88, 138, 0.6); }

.btn-shadow-primary:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(84, 92, 216, 0.4), 0 0.0625rem 0.125rem rgba(84, 92, 216, 0.5); }

.btn-shadow-secondary:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(108, 117, 125, 0.4), 0 0.0625rem 0.125rem rgba(108, 117, 125, 0.5); }

.btn-shadow-success:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(58, 196, 125, 0.4), 0 0.0625rem 0.125rem rgba(58, 196, 125, 0.5); }

.btn-shadow-info:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(48, 177, 255, 0.4), 0 0.0625rem 0.125rem rgba(48, 177, 255, 0.5); }

.btn-shadow-warning:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(247, 185, 36, 0.4), 0 0.0625rem 0.125rem rgba(247, 185, 36, 0.5); }

.btn-shadow-danger:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(217, 37, 80, 0.4), 0 0.0625rem 0.125rem rgba(217, 37, 80, 0.5); }

.btn-shadow-light:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(238, 238, 238, 0.4), 0 0.0625rem 0.125rem rgba(238, 238, 238, 0.5); }

.btn-shadow-dark:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(52, 58, 64, 0.4), 0 0.0625rem 0.125rem rgba(52, 58, 64, 0.5); }

.btn-shadow-focus:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(68, 64, 84, 0.4), 0 0.0625rem 0.125rem rgba(68, 64, 84, 0.5); }

.btn-shadow-alternate:hover {
  box-shadow: 0 0.125rem 0.625rem rgba(131, 88, 138, 0.4), 0 0.0625rem 0.125rem rgba(131, 88, 138, 0.5); }

.btn-outline-primary {
  color: #545cd8;
  border-color: #545cd8; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #545cd8;
    border-color: #545cd8; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0 rgba(84, 92, 216, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #545cd8;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #545cd8;
    border-color: #545cd8; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(84, 92, 216, 0.5); }
  .btn-outline-primary.btn-shadow {
    box-shadow: 0 0.125rem 0.625rem rgba(84, 92, 216, 0.4), 0 0.0625rem 0.125rem rgba(84, 92, 216, 0.5); }
    .btn-outline-primary.btn-shadow.active:hover, .btn-outline-primary.btn-shadow.disabled:hover, .btn-outline-primary.btn-shadow:active:hover, .btn-outline-primary.btn-shadow:disabled:hover {
      box-shadow: 0 0.125rem 0.625rem rgba(84, 92, 216, 0.5), 0 0.0625rem 0.125rem rgba(84, 92, 216, 0.6); }
    .btn-outline-primary.btn-shadow:hover {
      box-shadow: 0px 5px 15px 2px rgba(84, 92, 216, 0.19); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0 rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.btn-shadow {
    box-shadow: 0 0.125rem 0.625rem rgba(108, 117, 125, 0.4), 0 0.0625rem 0.125rem rgba(108, 117, 125, 0.5); }
    .btn-outline-secondary.btn-shadow.active:hover, .btn-outline-secondary.btn-shadow.disabled:hover, .btn-outline-secondary.btn-shadow:active:hover, .btn-outline-secondary.btn-shadow:disabled:hover {
      box-shadow: 0 0.125rem 0.625rem rgba(108, 117, 125, 0.5), 0 0.0625rem 0.125rem rgba(108, 117, 125, 0.6); }
    .btn-outline-secondary.btn-shadow:hover {
      box-shadow: 0px 5px 15px 2px rgba(108, 117, 125, 0.19); }

.btn-outline-success {
  color: #3ac47d;
  border-color: #3ac47d; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #3ac47d;
    border-color: #3ac47d; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0 rgba(58, 196, 125, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #3ac47d;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #3ac47d;
    border-color: #3ac47d; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(58, 196, 125, 0.5); }
  .btn-outline-success.btn-shadow {
    box-shadow: 0 0.125rem 0.625rem rgba(58, 196, 125, 0.4), 0 0.0625rem 0.125rem rgba(58, 196, 125, 0.5); }
    .btn-outline-success.btn-shadow.active:hover, .btn-outline-success.btn-shadow.disabled:hover, .btn-outline-success.btn-shadow:active:hover, .btn-outline-success.btn-shadow:disabled:hover {
      box-shadow: 0 0.125rem 0.625rem rgba(58, 196, 125, 0.5), 0 0.0625rem 0.125rem rgba(58, 196, 125, 0.6); }
    .btn-outline-success.btn-shadow:hover {
      box-shadow: 0px 5px 15px 2px rgba(58, 196, 125, 0.19); }

.btn-outline-info {
  color: #30b1ff;
  border-color: #30b1ff; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #30b1ff;
    border-color: #30b1ff; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0 rgba(48, 177, 255, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #30b1ff;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #30b1ff;
    border-color: #30b1ff; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(48, 177, 255, 0.5); }
  .btn-outline-info.btn-shadow {
    box-shadow: 0 0.125rem 0.625rem rgba(48, 177, 255, 0.4), 0 0.0625rem 0.125rem rgba(48, 177, 255, 0.5); }
    .btn-outline-info.btn-shadow.active:hover, .btn-outline-info.btn-shadow.disabled:hover, .btn-outline-info.btn-shadow:active:hover, .btn-outline-info.btn-shadow:disabled:hover {
      box-shadow: 0 0.125rem 0.625rem rgba(48, 177, 255, 0.5), 0 0.0625rem 0.125rem rgba(48, 177, 255, 0.6); }
    .btn-outline-info.btn-shadow:hover {
      box-shadow: 0px 5px 15px 2px rgba(48, 177, 255, 0.19); }

.btn-outline-warning {
  color: #f7b924;
  border-color: #f7b924; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #f7b924;
    border-color: #f7b924; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0 rgba(247, 185, 36, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #f7b924;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #f7b924;
    border-color: #f7b924; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(247, 185, 36, 0.5); }
  .btn-outline-warning.btn-shadow {
    box-shadow: 0 0.125rem 0.625rem rgba(247, 185, 36, 0.4), 0 0.0625rem 0.125rem rgba(247, 185, 36, 0.5); }
    .btn-outline-warning.btn-shadow.active:hover, .btn-outline-warning.btn-shadow.disabled:hover, .btn-outline-warning.btn-shadow:active:hover, .btn-outline-warning.btn-shadow:disabled:hover {
      box-shadow: 0 0.125rem 0.625rem rgba(247, 185, 36, 0.5), 0 0.0625rem 0.125rem rgba(247, 185, 36, 0.6); }
    .btn-outline-warning.btn-shadow:hover {
      box-shadow: 0px 5px 15px 2px rgba(247, 185, 36, 0.19); }

.btn-outline-danger {
  color: #d92550;
  border-color: #d92550; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #d92550;
    border-color: #d92550; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0 rgba(217, 37, 80, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #d92550;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #d92550;
    border-color: #d92550; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(217, 37, 80, 0.5); }
  .btn-outline-danger.btn-shadow {
    box-shadow: 0 0.125rem 0.625rem rgba(217, 37, 80, 0.4), 0 0.0625rem 0.125rem rgba(217, 37, 80, 0.5); }
    .btn-outline-danger.btn-shadow.active:hover, .btn-outline-danger.btn-shadow.disabled:hover, .btn-outline-danger.btn-shadow:active:hover, .btn-outline-danger.btn-shadow:disabled:hover {
      box-shadow: 0 0.125rem 0.625rem rgba(217, 37, 80, 0.5), 0 0.0625rem 0.125rem rgba(217, 37, 80, 0.6); }
    .btn-outline-danger.btn-shadow:hover {
      box-shadow: 0px 5px 15px 2px rgba(217, 37, 80, 0.19); }

.btn-outline-light {
  color: #eeeeee;
  border-color: #eeeeee; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #eeeeee;
    border-color: #eeeeee; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0 rgba(238, 238, 238, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #eeeeee;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #eeeeee;
    border-color: #eeeeee; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(238, 238, 238, 0.5); }
  .btn-outline-light.btn-shadow {
    box-shadow: 0 0.125rem 0.625rem rgba(238, 238, 238, 0.4), 0 0.0625rem 0.125rem rgba(238, 238, 238, 0.5); }
    .btn-outline-light.btn-shadow.active:hover, .btn-outline-light.btn-shadow.disabled:hover, .btn-outline-light.btn-shadow:active:hover, .btn-outline-light.btn-shadow:disabled:hover {
      box-shadow: 0 0.125rem 0.625rem rgba(238, 238, 238, 0.5), 0 0.0625rem 0.125rem rgba(238, 238, 238, 0.6); }
    .btn-outline-light.btn-shadow:hover {
      box-shadow: 0px 5px 15px 2px rgba(238, 238, 238, 0.19); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0 rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.btn-shadow {
    box-shadow: 0 0.125rem 0.625rem rgba(52, 58, 64, 0.4), 0 0.0625rem 0.125rem rgba(52, 58, 64, 0.5); }
    .btn-outline-dark.btn-shadow.active:hover, .btn-outline-dark.btn-shadow.disabled:hover, .btn-outline-dark.btn-shadow:active:hover, .btn-outline-dark.btn-shadow:disabled:hover {
      box-shadow: 0 0.125rem 0.625rem rgba(52, 58, 64, 0.5), 0 0.0625rem 0.125rem rgba(52, 58, 64, 0.6); }
    .btn-outline-dark.btn-shadow:hover {
      box-shadow: 0px 5px 15px 2px rgba(52, 58, 64, 0.19); }

.btn-outline-focus {
  color: #444054;
  border-color: #444054; }
  .btn-outline-focus:hover {
    color: #fff;
    background-color: #444054;
    border-color: #444054; }
  .btn-outline-focus:focus, .btn-outline-focus.focus {
    box-shadow: 0 0 0 0 rgba(68, 64, 84, 0.5); }
  .btn-outline-focus.disabled, .btn-outline-focus:disabled {
    color: #444054;
    background-color: transparent; }
  .btn-outline-focus:not(:disabled):not(.disabled):active, .btn-outline-focus:not(:disabled):not(.disabled).active,
  .show > .btn-outline-focus.dropdown-toggle {
    color: #fff;
    background-color: #444054;
    border-color: #444054; }
    .btn-outline-focus:not(:disabled):not(.disabled):active:focus, .btn-outline-focus:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-focus.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(68, 64, 84, 0.5); }
  .btn-outline-focus.btn-shadow {
    box-shadow: 0 0.125rem 0.625rem rgba(68, 64, 84, 0.4), 0 0.0625rem 0.125rem rgba(68, 64, 84, 0.5); }
    .btn-outline-focus.btn-shadow.active:hover, .btn-outline-focus.btn-shadow.disabled:hover, .btn-outline-focus.btn-shadow:active:hover, .btn-outline-focus.btn-shadow:disabled:hover {
      box-shadow: 0 0.125rem 0.625rem rgba(68, 64, 84, 0.5), 0 0.0625rem 0.125rem rgba(68, 64, 84, 0.6); }
    .btn-outline-focus.btn-shadow:hover {
      box-shadow: 0px 5px 15px 2px rgba(68, 64, 84, 0.19); }

.btn-outline-alternate {
  color: #83588a;
  border-color: #83588a; }
  .btn-outline-alternate:hover {
    color: #fff;
    background-color: #83588a;
    border-color: #83588a; }
  .btn-outline-alternate:focus, .btn-outline-alternate.focus {
    box-shadow: 0 0 0 0 rgba(131, 88, 138, 0.5); }
  .btn-outline-alternate.disabled, .btn-outline-alternate:disabled {
    color: #83588a;
    background-color: transparent; }
  .btn-outline-alternate:not(:disabled):not(.disabled):active, .btn-outline-alternate:not(:disabled):not(.disabled).active,
  .show > .btn-outline-alternate.dropdown-toggle {
    color: #fff;
    background-color: #83588a;
    border-color: #83588a; }
    .btn-outline-alternate:not(:disabled):not(.disabled):active:focus, .btn-outline-alternate:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-alternate.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(131, 88, 138, 0.5); }
  .btn-outline-alternate.btn-shadow {
    box-shadow: 0 0.125rem 0.625rem rgba(131, 88, 138, 0.4), 0 0.0625rem 0.125rem rgba(131, 88, 138, 0.5); }
    .btn-outline-alternate.btn-shadow.active:hover, .btn-outline-alternate.btn-shadow.disabled:hover, .btn-outline-alternate.btn-shadow:active:hover, .btn-outline-alternate.btn-shadow:disabled:hover {
      box-shadow: 0 0.125rem 0.625rem rgba(131, 88, 138, 0.5), 0 0.0625rem 0.125rem rgba(131, 88, 138, 0.6); }
    .btn-outline-alternate.btn-shadow:hover {
      box-shadow: 0px 5px 15px 2px rgba(131, 88, 138, 0.19); }

.btn {
  position: relative;
  transition: color 0.15s, background-color 0.15s, border-color 0.15s, box-shadow 0.15s; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }

.btn-light {
  border-color: gainsboro; }

.btn-outline-light {
  color: #8f8f8f; }

.card {
  box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03), 0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03), 0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05), 0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
  border-width: 0;
  transition: all .2s; }
  .card > .dropdown-menu-header {
    margin: 0; }
    .card > .dropdown-menu-header .dropdown-menu-header-inner {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem; }
  .card.text-dark.text-white .card-footer,
  .card.text-dark.text-white .card-header, .card.text-white .card-footer,
  .card.text-white .card-header {
    background: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.9); }
  .card.text-dark.text-white .card-footer,
  .card.text-dark.text-white .card-header {
    color: rgba(0, 0, 0, 0.9); }
  .card .card-footer {
    display: flex;
    align-items: center; }

.btn-actions-pane-right {
  margin-left: auto;
  white-space: nowrap; }

.btn-actions-pane-left {
  margin-right: auto; }

.actions-icon-btn .btn-icon-only {
  padding-left: 0;
  padding-right: 0;
  color: #495057; }
  .actions-icon-btn .btn-icon-only .btn-icon-wrapper {
    font-size: 1.3rem;
    width: 30px;
    text-align: center; }
  .actions-icon-btn .btn-icon-only:hover {
    color: #545cd8; }

.card-header,
.card-title {
  text-transform: uppercase;
  color: rgba(18, 21, 78, 0.7);
  font-weight: bold;
  font-size: 0.88rem; }

.card-header {
  display: flex;
  align-items: center;
  border-bottom-width: 1px;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0.625rem;
  height: 3.5rem; }
  .card-header.no-border {
    border: 0;
    padding: 0;
    height: auto; }
  .card-header .menu-header-subtitle {
    display: block; }
  .card-header.card-header-tab .nav {
    width: auto;
    margin-left: auto; }
  .card-header.card-header-tab .card-header-title {
    display: flex;
    align-items: center;
    white-space: nowrap; }
  .card-header .header-icon {
    font-size: 1.65rem;
    margin-right: 0.625rem; }
  .card-header > .nav {
    margin-left: -0.625rem;
    height: 100%;
    width: 100%; }
    .card-header > .nav .nav-item {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center; }
    .card-header > .nav .nav-link {
      text-transform: none;
      width: 100%;
      display: block;
      color: #495057; }
      .card-header > .nav .nav-link::before {
        content: '';
        border-radius: 15px;
        background: #545cd8;
        transition: all .2s;
        height: 4px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -2px;
        opacity: 0; }
      .card-header > .nav .nav-link:hover {
        color: #545cd8; }
      .card-header > .nav .nav-link.active {
        color: #545cd8; }
        .card-header > .nav .nav-link.active::before {
          opacity: 1; }
  .card-header.card-header-tab-animation .nav .nav-link::before {
    transform: scale(0);
    opacity: 1;
    width: 90%;
    left: 5%; }
  .card-header.card-header-tab-animation .nav .nav-link.active::before, .card-header.card-header-tab-animation .nav .nav-link:hover::before {
    transform: scale(1); }

.card-border {
  box-shadow: 0 0 0 transparent;
  border-width: 1px; }

.card-hover-shadow:hover {
  box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03), 0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03), 0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05), 0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03); }

.card-hover-shadow-2x:hover {
  box-shadow: 0 0.66875rem 2.3875rem rgba(8, 10, 37, 0.03), 0 1.1375rem 1.60625rem rgba(8, 10, 37, 0.03), 0 0.45rem 0.73125rem rgba(8, 10, 37, 0.05), 0 0.325rem 0.3875rem rgba(8, 10, 37, 0.03); }

.card-subtitle {
  margin-bottom: 0.75rem;
  font-size: 0.968rem;
  color: rgba(18, 21, 78, 0.55); }

.card-shadow-primary {
  box-shadow: 0 0.46875rem 2.1875rem rgba(84, 92, 216, 0.03), 0 0.9375rem 1.40625rem rgba(84, 92, 216, 0.03), 0 0.25rem 0.53125rem rgba(84, 92, 216, 0.05), 0 0.125rem 0.1875rem rgba(84, 92, 216, 0.03); }

.card-shadow-secondary {
  box-shadow: 0 0.46875rem 2.1875rem rgba(108, 117, 125, 0.03), 0 0.9375rem 1.40625rem rgba(108, 117, 125, 0.03), 0 0.25rem 0.53125rem rgba(108, 117, 125, 0.05), 0 0.125rem 0.1875rem rgba(108, 117, 125, 0.03); }

.card-shadow-success {
  box-shadow: 0 0.46875rem 2.1875rem rgba(58, 196, 125, 0.03), 0 0.9375rem 1.40625rem rgba(58, 196, 125, 0.03), 0 0.25rem 0.53125rem rgba(58, 196, 125, 0.05), 0 0.125rem 0.1875rem rgba(58, 196, 125, 0.03); }

.card-shadow-info {
  box-shadow: 0 0.46875rem 2.1875rem rgba(48, 177, 255, 0.03), 0 0.9375rem 1.40625rem rgba(48, 177, 255, 0.03), 0 0.25rem 0.53125rem rgba(48, 177, 255, 0.05), 0 0.125rem 0.1875rem rgba(48, 177, 255, 0.03); }

.card-shadow-warning {
  box-shadow: 0 0.46875rem 2.1875rem rgba(247, 185, 36, 0.03), 0 0.9375rem 1.40625rem rgba(247, 185, 36, 0.03), 0 0.25rem 0.53125rem rgba(247, 185, 36, 0.05), 0 0.125rem 0.1875rem rgba(247, 185, 36, 0.03); }

.card-shadow-danger {
  box-shadow: 0 0.46875rem 2.1875rem rgba(217, 37, 80, 0.03), 0 0.9375rem 1.40625rem rgba(217, 37, 80, 0.03), 0 0.25rem 0.53125rem rgba(217, 37, 80, 0.05), 0 0.125rem 0.1875rem rgba(217, 37, 80, 0.03); }

.card-shadow-light {
  box-shadow: 0 0.46875rem 2.1875rem rgba(238, 238, 238, 0.03), 0 0.9375rem 1.40625rem rgba(238, 238, 238, 0.03), 0 0.25rem 0.53125rem rgba(238, 238, 238, 0.05), 0 0.125rem 0.1875rem rgba(238, 238, 238, 0.03); }

.card-shadow-dark {
  box-shadow: 0 0.46875rem 2.1875rem rgba(52, 58, 64, 0.03), 0 0.9375rem 1.40625rem rgba(52, 58, 64, 0.03), 0 0.25rem 0.53125rem rgba(52, 58, 64, 0.05), 0 0.125rem 0.1875rem rgba(52, 58, 64, 0.03); }

.card-shadow-focus {
  box-shadow: 0 0.46875rem 2.1875rem rgba(68, 64, 84, 0.03), 0 0.9375rem 1.40625rem rgba(68, 64, 84, 0.03), 0 0.25rem 0.53125rem rgba(68, 64, 84, 0.05), 0 0.125rem 0.1875rem rgba(68, 64, 84, 0.03); }

.card-shadow-alternate {
  box-shadow: 0 0.46875rem 2.1875rem rgba(131, 88, 138, 0.03), 0 0.9375rem 1.40625rem rgba(131, 88, 138, 0.03), 0 0.25rem 0.53125rem rgba(131, 88, 138, 0.05), 0 0.125rem 0.1875rem rgba(131, 88, 138, 0.03); }

.card-header-lg {
  padding: 1.5rem 2.5rem;
  height: auto; }

.sticky-active-class .sticky-inner-wrapper > div {
  box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03), 0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03), 0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05), 0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03); }

.accordion-wrapper {
  border-radius: 0.25rem;
  border: #e9ecef solid 1px; }
  .accordion-wrapper > .card {
    box-shadow: 0 0 0 0 transparent; }
    .accordion-wrapper > .card > .card-header {
      padding: 1rem;
      height: auto; }
      .accordion-wrapper > .card > .card-header .btn:active,
      .accordion-wrapper > .card > .card-header .btn:focus,
      .accordion-wrapper > .card > .card-header .btn:hover {
        text-decoration: none; }
      .accordion-wrapper > .card > .card-header .form-heading p {
        margin: 0; }
    .accordion-wrapper > .card .collapse {
      border-bottom: transparent solid 1px; }
      .accordion-wrapper > .card .collapse.show {
        border-bottom-color: #e9ecef; }

.icon-wrapper {
  display: flex;
  align-content: center;
  align-items: center; }

.widget-chart {
  text-align: center;
  padding: 1rem;
  position: relative; }
  .widget-chart .progress-sub-label {
    opacity: .8;
    padding: 5px 0 0; }
  .widget-chart .progress-circle-wrapper {
    min-width: 68px;
    margin-right: 1rem; }
    .widget-chart .progress-circle-wrapper .react-sweet-progress-symbol {
      font-size: 0.8rem; }
  .widget-chart .widget-chart-content {
    position: relative;
    z-index: 5; }
  .widget-chart .widget-chart-content-lg {
    padding: 2rem 0 1rem 2rem; }
    .widget-chart .widget-chart-content-lg .widget-numbers {
      margin-bottom: 0; }
  .widget-chart .widget-chart-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: .25;
    z-index: 6;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    overflow: hidden; }
  .widget-chart .widget-numbers {
    font-weight: bold;
    font-size: 2.5rem;
    display: block;
    line-height: 1;
    margin: 1rem auto; }
    .widget-chart .widget-numbers + .widget-chart-flex,
    .widget-chart .widget-numbers + .widget-description,
    .widget-chart .widget-numbers + .widget-subheading {
      margin-top: -0.5rem; }
  .widget-chart .widget-subheading {
    margin: -0.5rem 0 0;
    display: block;
    opacity: .6; }
    .widget-chart .widget-subheading:first-child {
      margin-top: 0; }
    .widget-chart .widget-subheading + .widget-numbers {
      margin-top: 0.5rem; }
  .widget-chart .widget-description {
    margin: 1rem 0 0; }
  .widget-chart.widget-chart-hover {
    transition: all .2s; }
    .widget-chart.widget-chart-hover:hover {
      z-index: 15;
      transform: scale(1.15);
      box-shadow: 0 0.46875rem 4.1875rem rgba(8, 10, 37, 0.05), 0 0.9375rem 2.40625rem rgba(8, 10, 37, 0.05), 0 0.25rem 1.3125rem rgba(8, 10, 37, 0.06), 0 0.125rem 1.1875rem rgba(8, 10, 37, 0.06);
      cursor: pointer;
      background: #fff; }
  .widget-chart .widget-chart-actions {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    z-index: 12; }
    .widget-chart .widget-chart-actions .btn-link {
      font-size: 1.1rem;
      padding-top: 0;
      padding-bottom: 0;
      opacity: .6; }
  .widget-chart .widget-progress-wrapper {
    margin-top: 1rem; }
    .widget-chart .widget-progress-wrapper.progress-wrapper-bottom {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%; }
      .widget-chart .widget-progress-wrapper.progress-wrapper-bottom .progress {
        margin: 0 -1px -1px; }
      .widget-chart .widget-progress-wrapper.progress-wrapper-bottom .progress {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem; }
        .widget-chart .widget-progress-wrapper.progress-wrapper-bottom .progress .progress-bar {
          border-bottom-left-radius: 0.25rem; }
  .widget-chart .widget-chart-flex {
    display: flex;
    align-items: center;
    align-content: center;
    margin-bottom: 1rem; }
    .widget-chart .widget-chart-flex:last-child {
      margin-bottom: 0; }
    .widget-chart .widget-chart-flex .widget-subheading {
      margin: 0; }
    .widget-chart .widget-chart-flex .widget-description {
      margin-top: 0; }
  .widget-chart.text-left {
    flex-direction: row;
    align-items: center; }
    .widget-chart.text-left .icon-wrapper {
      min-width: 54px;
      margin: 0 1rem 0 0; }
    .widget-chart.text-left .widget-numbers {
      margin-left: 0; }
    .widget-chart.text-left .widget-chart-content {
      display: flex;
      flex-direction: column;
      align-content: center;
      flex: 1;
      position: relative; }
      .widget-chart.text-left .widget-chart-content > .widget-numbers:first-child {
        margin-top: 0; }
      .widget-chart.text-left .widget-chart-content .widget-description {
        align-self: flex-start; }
    .widget-chart.text-left .widget-chart-wrapper {
      height: 35%; }
  .widget-chart.widget-chart-left {
    padding-bottom: 15%; }
  .widget-chart .chart-wrapper-relative {
    position: relative;
    opacity: 1;
    margin-top: 1rem; }

.widget-chart-actions {
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 12; }
  .widget-chart-actions .btn-link {
    font-size: 1.1rem;
    padding-top: 0;
    padding-bottom: 0;
    opacity: .6; }

.widget-chart:hover .widget-chart-actions .btn-link,
.widget-content:hover .widget-chart-actions .btn-link {
  opacity: 1; }

.grid-menu .widget-chart.widget-chart-hover:hover {
  background: #fff;
  border-radius: 0.25rem; }

.icon-wrapper {
  width: 54px;
  height: 54px;
  margin: 0 auto;
  position: relative;
  overflow: hidden; }
  .icon-wrapper[class*="border-"] {
    border-width: 1px;
    border-style: solid; }
  .icon-wrapper .icon-wrapper-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 3;
    opacity: .2; }
    .icon-wrapper .icon-wrapper-bg.bg-light {
      opacity: .08; }
  .icon-wrapper i {
    margin: 0 auto;
    font-size: 1.7rem;
    position: relative;
    z-index: 5; }
    .icon-wrapper i:before {
      margin-top: -3px; }
  .icon-wrapper .progress-circle-wrapper {
    width: 100%;
    margin-right: 0; }

.widget-chart2 .widget-chart-flex {
  display: flex;
  align-items: baseline;
  align-content: center;
  margin-bottom: 0; }
  .widget-chart2 .widget-chart-flex .widget-subtitle {
    margin-left: auto; }
  .widget-chart2 .widget-chart-flex .widget-numbers {
    font-weight: normal; }
  .widget-chart2 .widget-chart-flex + .widget-chart-flex .widget-numbers {
    margin-bottom: 0; }

.widget-chart2 .widget-chat-wrapper-outer {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 100%; }
  .widget-chart2 .widget-chat-wrapper-outer .widget-chart-wrapper {
    height: 70px;
    opacity: .8;
    position: relative;
    margin: 1rem auto -0.5rem; }
  .widget-chart2 .widget-chat-wrapper-outer .widget-chart-wrapper-lg {
    height: 130px; }

.card-btm-border {
  border-bottom: transparent solid 4px; }

.progress-box {
  text-align: center; }
  .progress-box h4 {
    font-size: 0.88rem;
    font-weight: bold;
    opacity: .6;
    text-transform: uppercase;
    padding-bottom: 0.33333rem; }
  .progress-box svg {
    margin: 0 auto; }

.svg-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: .1; }
  .svg-bg svg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }

.widget-numbers-sm {
  font-size: 1.5rem; }

.widget-content {
  padding: 1rem;
  flex-direction: row;
  align-items: center; }
  .widget-content .widget-content-wrapper {
    display: flex;
    flex: 1;
    position: relative;
    align-items: center; }
  .widget-content .widget-content-left .widget-heading {
    opacity: .8;
    font-weight: bold; }
  .widget-content .widget-content-left .widget-subheading {
    opacity: .5; }
  .widget-content .widget-content-right {
    margin-left: auto; }
  .widget-content .widget-numbers {
    font-weight: bold;
    font-size: 1.8rem;
    display: block; }
  .widget-content .widget-content-outer {
    display: flex;
    flex: 1;
    flex-direction: column; }
  .widget-content .widget-progress-wrapper {
    margin-top: 1rem; }
    .widget-content .widget-progress-wrapper .progress-sub-label {
      margin-top: 0.33333rem;
      opacity: .5;
      display: flex;
      align-content: center;
      align-items: center; }
      .widget-content .widget-progress-wrapper .progress-sub-label .sub-label-right {
        margin-left: auto; }
  .widget-content .widget-content-right.widget-content-actions {
    visibility: hidden;
    opacity: 0;
    transition: opacity .2s; }
  .widget-content:hover .widget-content-right.widget-content-actions {
    visibility: visible;
    opacity: 1; }
