body {
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

pre {
  white-space: pre-wrap;
}

table.fc-list-table > tbody > tr > td:nth-child(2) {
  display: none;
}
